import React, { useEffect } from 'react';

import { REACT_APP_FULLSTORY_ORG_ID } from './config';

import * as FullStory from '@fullstory/browser';

FullStory.init({ orgId: REACT_APP_FULLSTORY_ORG_ID });

/*
  Each Coco robot has a QR code of the url: https://merchant-qr.cocodelivery.com/robots/:robotId
  Since QR app is now handled by a different app, the whole purpose of this app is to forward requests to the new one

  https://merchant-qr.cocodelivery.com -->  https://qr.cocodelivery.com
  https://merchant-qr.cocodelivery.com/robots/:robotId --> https://qr.cocodelivery.com/orders?cocoId=:robotId
*/
function App() {
  useEffect(() => {
    if (window) {
      const currentPathname = window.location.pathname;
      // legacy app route is /robots/:robotId, new app is /orders?cocoId=:robotId
      if (currentPathname.startsWith('/robot')) {
        /*
          '/robots/C10710' --> ['', 'robots', 'C10710'] --> ['C10710'] --> 'C10710'
        */
        const robotId = currentPathname.split('/').slice(-1)[0];
        return window.location.replace(
          `https://qr.${process.env.REACT_APP_SUBDOMAIN_NAME}.cocodelivery.com/orders?cocoId=${robotId}`
        );
      } else {
        return window.location.replace(
          `https://qr.${process.env.REACT_APP_SUBDOMAIN_NAME}.cocodelivery.com`
        );
      }
    }
  }, []);
  return null;
}

export default App;
